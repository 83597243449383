import {validateCollectionName} from '@/services/collections/api';

export function collectionNameValidationRules(): Record<string, unknown> {
  return {
    required: true,
    max: 66,
    unique: {
      validator: validateCollectionName
    },
  };
}
