import Vue from 'vue';
import {Component, Inject, Prop, Ref} from 'vue-property-decorator';
import {
  createUserCollection,
  getUserCollections,
  updateCollection,
  validateCollectionName
} from '@/services/collections/api';
import {CollectionCardData} from '@/utilities/cmsUtilities';
import CollectionCardComponent from '@/commoncomponents/CollectionCardComponent/CollectionCardComponent.vue';
import collectionsStore from '@/store/modules/collectionsStore';
import {CollectionsDto, CreateUserCollectionRequest, UpdateCollectionRequest} from '@/Model/collections/types';
import APP_UTILITIES from '@/utilities/commonFunctions';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import {AxiosError} from 'axios';
import InfiniteLoading from 'vue-infinite-loading';
import {ToastType} from '@/Model/toastType';
import {ToastPosition} from '@/Model/toastPosition';
import ConfigureLearningLibraryCollection from '@/components/learningLibrary/ConfigureLearningLibraryCollection.vue';
import CollectionForm from '@/components/myCollectionsFlyout/CollectionForm/CollectionForm.vue';
import {ProviderInstance} from 'vee-validate/dist/types/types';
import {ValidationProvider} from 'vee-validate';
import { AnalyticsInjectionKey, AnalyticsService, analyticsEventNames } from '@/analytics';
import {collectionNameValidationRules} from "@/utilities/collectionsUtilities";

@Component({
  name: 'MyCollectionsFlyout',
  components: {
    CollectionForm,
    ConfigureLearningLibraryCollection,
    CollectionCardComponent,
    BouncingPreloaderComponent: BouncingPreloaderComponent,
    InfiniteLoading,
    ValidationProvider,
  }
})
export default class MyCollectionsFlyout extends Vue {
  @Prop({type: Boolean})
  isVisible: boolean = false;

  @Inject(AnalyticsInjectionKey)
  private readonly analyticsService!: AnalyticsService;

  @Ref('nameValidationProvider')
  public nameValidationProvider?: ProviderInstance;

  public collections: CollectionsDto[] = [];
  public selectedCollections: CollectionCardData[] = [];

  public isLoadingCollections: boolean = false;
  public isSavingCollections: boolean = false;
  public getUserCollectionsErrorMessage: string | null = null;

  public totalItems: number = 0;
  public pagination = {
    page: 0,
    max: 25,
  };

  public showForm: boolean = false;
  public collectionName: string = '';

  contentSaved: Set<string> = new Set([]);
  get totalItemsSaved() {
    return this.contentSaved.size;
  }

  public get collectionsCardData(): CollectionCardData[] {
    return this.collections.map(item => ({
      title: item.name,
      collectionId: item.id.toString(),
      isLocked: item.isLocked,
      totalItems: (
        item.posts
          ? item.posts.length
          : 0
      ),
      items: item.posts
    }));
  }

  get nameValidationRules(): Record<string, unknown> {
    return collectionNameValidationRules();
  }

  private getPayload(): UpdateCollectionRequest[] {
    const selectedContent = collectionsStore.selectedContent;
    const userId = APP_UTILITIES.getUserID();
    return this.selectedCollections.map(c => {
      const collectionsPosts = new Set(c.items);
      selectedContent.forEach(c => {
        if (!collectionsPosts.has(c)) {
          collectionsPosts.add(c);
          this.contentSaved.add(c);
        }
      });
      return {
        id: parseInt(c.collectionId),
        name: c.title,
        isLocked: c.isLocked,
        posts: Array.from(collectionsPosts.values()),
        userId,
      };
    });
  }

  public async handleInfiniteLoader($state: {
    loaded: () => void;
    complete: () => void;
  }): Promise<void> {
    try {
      this.isLoadingCollections = true;
      const newPage = this.pagination.page + 1;
      const {data: {items, totalItems}} = await getUserCollections({
        page: newPage,
        max: this.pagination.max
      });

      this.collections = [
        ...this.collections,
        ...items
      ];
      this.pagination.page = newPage;
      this.totalItems = totalItems;

      if (this.collections.length === totalItems) {
        $state.complete();
      } else {
        $state.loaded();
      }
    } catch(e) {
      this.getUserCollectionsErrorMessage = (e as AxiosError | Error).message;
      throw e;
    } finally {
      this.isLoadingCollections = false;
    }
  }

  public handleCancel() {
    this.$emit('cancel');
    this.setDefaults();
  }

  public handleCollectionSelected( options: { selected: boolean; data: CollectionCardData } ) {
    const collectionIndex = this.selectedCollections.findIndex(c => c.collectionId === options.data.collectionId);
    if (options.selected) {
      if (collectionIndex === -1) {
        this.selectedCollections.push(options.data);
      }
    } else {
      if (collectionIndex !== -1) {
        this.selectedCollections.splice(collectionIndex, 1);
      }
    }
  }

  public async handleSave() {
    const payload = this.getPayload();
    const promises = payload.map(p => updateCollection(p));

    try {
      /* Create a new collection with the provided name and add the selected content */
      if (this.showForm && this.nameValidationProvider) {
        const {valid} = await this.nameValidationProvider.validate();
        if (!valid) {
          return;
        }
        const newCollection: CreateUserCollectionRequest = {
          name: this.collectionName,
          isLocked: false,
          userId: APP_UTILITIES.getUserID(),
          posts: collectionsStore.selectedContent
        };

        await createUserCollection(newCollection);
        collectionsStore.selectedContent.forEach(contentId => this.contentSaved.add(contentId));
      }

      this.isSavingCollections = true;
      const response = await Promise.all(promises);
      if (response) {
        this.analyticsService.track(analyticsEventNames.LEARNING_LIBRARY_CONTENT_ADDED_TO_A_COLLECTION);
        collectionsStore.clearSelection();
        this.handleCancel();
        APP_UTILITIES.showToastMessage(
          `${this.totalItemsSaved > 0 ? this.totalItemsSaved : 'No Items'} Saved`,
          ToastType.Success,
          ToastPosition.BottomCenter
        );
      }
      this.resetValidation();
      this.collectionName = '';
      this.showForm = false;
    } catch (e) {
      console.error('Update error', e);
    } finally {
      this.isSavingCollections = false;
      this.selectedCollections = [];
    }
  }

  private setDefaults() {
    this.collections = [];
    this.pagination.page = 0;
  }

  public toggleForm() {
    this.showForm = !this.showForm;
  }

  resetValidation(): void {
    this.nameValidationProvider && this.nameValidationProvider.reset();
  }
}
