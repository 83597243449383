import {
  CollectionsDto,
  CreateUserCollectionRequest,
  DeleteCollectionRequest,
  GetAllUserCollectionsRequest,
  GetCollectionRequest,
  GetUserCollectionsResponse,
  UpdateCollectionRequest,
  UserCollection
} from '@/Model/collections/types';
import API_CONST from '@/constants/ApiConst';
import { httpIdentityClient } from '../common/httpClient';

export async function getUserCollections(request: GetAllUserCollectionsRequest) {
  return httpIdentityClient().get<GetUserCollectionsResponse>(`${API_CONST.COLLECTIONS}`, { params: request });
}

export async function getDefaultCollections() {
  return httpIdentityClient().get<CollectionsDto[]>(`${API_CONST.COLLECTIONS}/default`);
}

export async function getCollection(request: GetCollectionRequest) {
  return httpIdentityClient().get<CollectionsDto>(`${API_CONST.COLLECTIONS}/${request.id}`);
}

export async function createUserCollection(request: CreateUserCollectionRequest) {
  return httpIdentityClient().post<UserCollection>(API_CONST.COLLECTIONS, request);
}

export async function updateCollection(request: UpdateCollectionRequest) {
  return httpIdentityClient().put<boolean>(`${API_CONST.COLLECTIONS}/${request.id}`, request);
}

export async function deleteUserCollection(request: DeleteCollectionRequest) {
  return httpIdentityClient().delete<boolean>(`${API_CONST.COLLECTIONS}/${request.id}`);
}

export async function validateCollectionName(name: string) {
  return httpIdentityClient().get<boolean>(`${API_CONST.COLLECTIONS}/exists?name=${name}`);
}
