


































































import MyCollectionsFlyout from './MyCollectionsFlyout';
export default MyCollectionsFlyout;
