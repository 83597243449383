
import { CollectionsDto } from '@/Model/collections/types';
import { ValidationProvider } from 'vee-validate';
import { ProviderInstance } from 'vee-validate/dist/types/types';
import { PropType } from 'vue';
import { Component, Model, Ref, Vue } from 'vue-property-decorator';
import {validateCollectionName} from '@/services/collections/api';
import {collectionNameValidationRules} from "@/utilities/collectionsUtilities";

@Component({
  components: {
    ValidationProvider,
  }
})
export default class ConfigureLearningLibraryCollection extends Vue {
  @Model('update:modelValue', { type: Object as PropType<CollectionsDto> })
  readonly modelValue!: CollectionsDto;

  @Ref('collectionValidationProvider')
  public collectionValidationProvider?: ProviderInstance;

  get collectionName(): string {
    return this.modelValue.name;
  }

  set collectionName(newValue: string) {
    this.$emit('update:modelValue', {
      ...this.modelValue,
      name: newValue,
    });
  }

  resetValidation(): void {
    this.collectionValidationProvider && this.collectionValidationProvider.reset();
  }

  get nameValidationRules(): Record<string, unknown> {
    return collectionNameValidationRules();
  }

  async save(): Promise<void> {
    if (!this.collectionValidationProvider) {
      return;
    }

    const { valid } = await this.collectionValidationProvider.validate();

    if (!valid) {
      return;
    }

    this.$emit('save');

    this.resetValidation();
  }

  cancel(): void {
    this.$emit('cancel');
    this.resetValidation();
  }
}
