import store from '@/store';
import {
  getModule,
  Module,
  VuexModule,
  Mutation
} from 'vuex-module-decorators';

@Module({
  namespaced: true,
  name: 'collectionsStore',
  store,
  dynamic: true
})
class collectionsModule extends VuexModule {
  private _selectedContent: Array<string> = [];

  get selectedContent(): Array<string> {
    return this._selectedContent;
  }

  get selectedContentCount(): number {
    return this._selectedContent.length;
  }

  get isContentSelected(): (contentId: string) => boolean {
    return (contentId: string) => {
      return this._selectedContent.includes(contentId);
    };
  }

  @Mutation
  public addSelectedContent(contentId: string) {
    if (!this._selectedContent.includes(contentId)) {
      this._selectedContent.push(contentId);
    }
  }

  @Mutation
  public removeSelectedContent(contentId: string) {
    this._selectedContent = this._selectedContent.filter(
      id => id !== contentId
    );
  }

  @Mutation
  public clearSelection() {
    this._selectedContent = [];
  }
}

export default getModule(collectionsModule);
