import { CollectionCardData } from '@/utilities/cmsUtilities';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import ContextMenu from '../contextMenuComponents/ContextMenu.vue';
import ContextMenuItem from '../contextMenuComponents/ContextMenuItem.vue';
import CmsCheckbox from "@/commoncomponents/Checkbox.vue";

@Component({
  name: 'collection-card',
  components: {
    CmsCheckbox,
    ContextMenu,
    ContextMenuItem,
  },
})
export default class CollectionCardComponent extends Vue {
  @Prop({ type: Object })
  cardData!: CollectionCardData;

  @Prop({ type: Boolean })
  small?: boolean;

  collectionChecked: boolean = false;

  get totalItemsText() {
    return `${this.cardData.totalItems} ${
      this.cardData.totalItems === 1
        ? 'item'
        : 'items'
    }`;
  }

  get routeTo(): Location {
    return {
      name: 'Single Collection',
      params: {
        collectionId: this.cardData.collectionId,
      }
    };
  }

  handleEdit(): void {
    this.$emit('edit', this.cardData);
  }

  handleDelete(): void {
    this.$emit('delete', this.cardData);
  }

  handleSelect(value: boolean): void {
    this.collectionChecked = value;
    this.$emit('select', {
      selected: this.collectionChecked,
      data: this.cardData,
    });
  }
}
