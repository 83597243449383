import Vue from 'vue';
import {Component, Model} from 'vue-property-decorator';

@Component({
  name: 'CollectionForm'
})
export default class CollectionForm extends Vue {
  @Model('update:collectionName', { type: String })
  readonly collectionName?: string;

  get name() {
    return this.collectionName || '';
  }

  set name(newValue: string) {
    this.$emit('update:collectionName', newValue);
  }
}
